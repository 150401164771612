import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="dropdown"
export default class extends Controller {
  static targets = ['menu'];

  toggle(event) {
    event.preventDefault();
    const isHidden = this.menuTarget.classList.contains('hidden');
    this.menuTarget.classList.toggle('hidden', !isHidden);

    // Update ARIA for accessibility
    const expanded = !isHidden;
    event.currentTarget.setAttribute('aria-expanded', expanded);
  }
}
