import { Application } from '@hotwired/stimulus';
import ScrollProgress from '@stimulus-components/scroll-progress';

const application = Application.start();
application.register('scroll-progress', ScrollProgress);

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

export { application };
