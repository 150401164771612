import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="theme"
export default class extends Controller {
  static targets = [
    'lightIcon',
    'darkIcon',
    'systemIcon',
    'dropdownButton',
    'lightItem',
    'darkItem',
    'systemItem',
  ];

  connect() {
    // Initialize system theme listener
    this.systemThemeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.handleSystemThemeChange = this.handleSystemThemeChange.bind(this);
    this.systemThemeMediaQuery.addEventListener('change', this.handleSystemThemeChange);

    // Apply the theme on page load
    this.applyTheme();
    this.updateUIState();
  }

  updateUIState() {
    const currentTheme = this.getCurrentTheme();

    // Update dropdown button icon
    this.lightIconTarget.classList.toggle('hidden', currentTheme !== 'light');
    this.darkIconTarget.classList.toggle('hidden', currentTheme !== 'dark');
    this.systemIconTarget.classList.toggle('hidden', currentTheme !== 'system');

    // Update menu items active state and colors
    this.lightItemTarget.classList.toggle('active', currentTheme === 'light');
    this.lightItemTarget.classList.toggle('text-sky-500', currentTheme === 'light');
    this.lightItemTarget.classList.toggle('dark:text-sky-500', false); // Never dark mode

    this.darkItemTarget.classList.toggle('active', currentTheme === 'dark');
    this.darkItemTarget.classList.toggle('text-sky-500', false); // Never light mode
    this.darkItemTarget.classList.toggle('dark:text-sky-500', currentTheme === 'dark');

    this.systemItemTarget.classList.toggle('active', currentTheme === 'system');
    this.systemItemTarget.classList.toggle('text-sky-500', currentTheme === 'system');
    this.systemItemTarget.classList.toggle('dark:text-sky-500', currentTheme === 'system');
  }

  setLightTheme() {
    localStorage.theme = 'light';
    this.applyTheme();
    this.updateUIState();
  }

  setDarkTheme() {
    localStorage.theme = 'dark';
    this.applyTheme();
    this.updateUIState();
  }

  setSystemTheme() {
    localStorage.removeItem('theme');
    this.applyTheme();
    this.updateUIState();
  }

  getCurrentTheme() {
    return localStorage.getItem('theme') || 'system';
  }

  applyTheme() {
    const storedTheme = localStorage.getItem('theme');

    if (storedTheme === 'dark') {
      this.enableDarkMode();
    } else if (storedTheme === 'light') {
      this.disableDarkMode();
    } else {
      // Respect the OS preference for system theme
      this.systemThemeMediaQuery.matches ? this.enableDarkMode() : this.disableDarkMode();
    }
  }

  handleSystemThemeChange(_e) {
    if (this.getCurrentTheme() === 'system') {
      this.applyTheme();
      this.updateUIState();
    }
  }

  enableDarkMode() {
    document.documentElement.classList.add('dark');
    this.updateUIState();
  }

  disableDarkMode() {
    document.documentElement.classList.remove('dark');
    this.updateUIState();
  }
}
