import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['infoPanel', 'info'];

  connect() {
    // Initialize touch coordinates
    this.touchStartY = 0;
    this.touchEndY = 0;

    console.log('Info controller connected');

    // Add click and touchstart outside listener
    this.outsideClickHandler = this.closePanelOnClickOutside.bind(this);
    document.addEventListener('click', this.outsideClickHandler);
    document.addEventListener('touchstart', this.outsideClickHandler);
    console.log('Add info click and touchstart outside event listeners');
  }

  disconnect() {
    console.log('Info controller disconnected');
    // Clean up listeners
    document.removeEventListener('click', this.outsideClickHandler);
    document.removeEventListener('touchstart', this.outsideClickHandler);
    console.log('Clean up info click and touchstart outside event listeners');
  }

  toggle(event) {
    event.preventDefault();
    event.stopPropagation();
    this.infoPanelTarget.classList.toggle('hidden');
    this.infoTarget.setAttribute(
      'aria-expanded',
      this.infoPanelTarget.classList.contains('hidden') ? 'false' : 'true'
    );
    console.log('Info controller toggle method fired.');
  }

  closePanelOnClickOutside(event) {
    console.log('Info controller closePanelOnClickOutside method fired.');
    if (
      !this.element.contains(event.target) &&
      !this.infoPanelTarget.classList.contains('hidden')
    ) {
      this.closePanel();
    }
  }

  touchStart(event) {
    console.log('Info controller touchStart method fired.');
    this.touchStartY = event.touches[0].clientY;
  }

  touchMove(event) {
    console.log('Info controller touchMove method fired.');
    this.touchEndY = event.touches[0].clientY;

    if (!this.infoPanelTarget.classList.contains('hidden')) {
      event.preventDefault();
    }
  }

  touchEnd() {
    console.log('Info controller touchEnd method fired.');

    // Guard clause to prevent processing if touch coordinates weren't set
    if (!this.touchStartY || !this.touchEndY) return;

    // Calculate the swipe distance (positive means swipe down)
    const swipeDistance = this.touchEndY - this.touchStartY;
    const minSwipeDistance = 50;

    // If swiped down more than min distance and panel is visible
    if (swipeDistance > minSwipeDistance && !this.infoPanelTarget.classList.contains('hidden')) {
      this.closePanel();
    }

    // Reset touch coordinates for next interaction
    this.touchStartY = 0;
    this.touchEndY = 0;
  }

  closePanel() {
    console.log('Info controller closePanel method fired.');
    this.infoPanelTarget.classList.add('hidden');
    this.infoTarget.setAttribute('aria-expanded', 'false');
  }
}
