import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button'];

  connect() {
    console.log('Top controller connected');
    this.toggleButtonVisibility(); // Initially toggle visibility
    this.scrollListener(); // Listen for scroll events
  }

  disconnect() {
    console.log('Top controller disconnected.');
    // Clean up event listener when the controller is disconnected
    window.removeEventListener('scroll', this.toggleButtonVisibility.bind(this));
  }

  toggleButtonVisibility() {
    console.log('Top controller toggleButtonVisibility method fired.');
    const btn = this.buttonTarget;

    // Get the height of the header
    const top = document.querySelector('head');
    const topHeight = top ? top.offsetHeight : 0;

    // Check if the user has scrolled past the home section
    if (window.scrollY > topHeight) {
      btn.classList.remove('hidden'); // Show the button
    } else {
      btn.classList.add('hidden'); // Hide the button
    }
  }

  scrollToTop() {
    console.log('Top controller scrollToTop method fired.');
    document.documentElement.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling animation
    });
  }

  // Listen for scroll events and toggle button visibility
  scrollListener() {
    console.log('Top controller scrollListener method fired.');
    window.addEventListener('scroll', this.toggleButtonVisibility.bind(this));
  }
}
