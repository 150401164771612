// Import necessary modules and styles
import '~/stylesheets/application.css';
import '~/controllers';
import '@hotwired/turbo-rails';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import * as CookieConsent from 'vanilla-cookieconsent';

// Global dataLayer initialization for Google Analytics
window.dataLayer = window.dataLayer || [];

// Define gtag function
function gtag() {
  if (window.dataLayer && typeof window.dataLayer.push === 'function') {
    window.dataLayer.push(arguments);
  } else {
    console.error('dataLayer is not properly initialized');
  }
}

// Assign gtag to window to make it globally available
window.gtag = gtag;

// Function to initialize Google Analytics
function initializeGoogleAnalytics() {
  console.log('Initializing Google Analytics');

  let script = document.createElement('script');
  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-681M591KBH';
  script.setAttribute('nonce', '<%= nonce %>');

  script.onload = function () {
    console.log('Google Analytics script loaded');
    gtag('js', new Date());
    gtag('config', 'G-681M591KBH');
  };

  script.onerror = function () {
    console.error('Failed to load Google Analytics script');
  };

  document.head.appendChild(script);
}

// Cookie Consent Initialization
function initializeCookieConsent() {
  if (CookieConsent.run) {
    CookieConsent.run({
      guiOptions: {
        consentModal: {
          layout: 'bar',
          position: 'bottom',
          flipButtons: false,
          equalWeightButtons: false,
        },
        preferencesModal: {
          layout: 'bar',
          position: 'right',
          flipButtons: false,
          equalWeightButtons: false,
        },
      },
      categories: {
        necessary: {
          readOnly: true, // this category cannot be disabled
        },
        functionality: {},
        marketing: {},
        analytics: {
          enabled: false,
          readOnly: false,
          autoClear: {
            cookies: [{ name: /^_ga/ }, { name: /^_gid/ }],
          },
        },
      },
      language: {
        default: 'en',
        autoDetect: 'browser',
        translations: {
          en: {
            consentModal: {
              title: 'We use cookies',
              description:
                'We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic.',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              showPreferencesBtn: 'Manage preferences',
              footer:
                '<a href="#link">Privacy Policy</a>\n<a href="#link">Terms and conditions</a>',
            },
            preferencesModal: {
              title: 'Consent Preferences Centre',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              savePreferencesBtn: 'Accept current selection',
              closeIconLabel: 'Close modal',
              serviceCounterLabel: 'Service | Services',
              sections: [
                {
                  title: 'Cookie Usage',
                  description:
                    'We use cookies to enhance your browsing experience, personalize content and ads, provide social media features, and analyze our traffic.',
                },
                {
                  title: 'Strictly Necessary Cookies <span class="pm__badge">Always Enabled</span>',
                  description: 'These cookies are essential for the website to function properly.',
                  linkedCategory: 'necessary',
                },
                {
                  title: 'Functional Cookies',
                  description: 'These cookies are needed for improved functionality of the site.',
                  linkedCategory: 'functionality',
                },
                {
                  title: 'Analytics cookies',
                  description:
                    'These cookies help us understand how visitors interact with the website.',
                  linkedCategory: 'analytics',
                },
                {
                  title: 'Advertisement Cookies',
                  description:
                    'These cookies are related to marketing campaigns via Google, Meta, and other social media.',
                  linkedCategory: 'marketing',
                },
                {
                  title: 'More information',
                  description:
                    'For any query in relation to my policy on cookies and your choices, please <a class="cc__link" href="hello@outsidershairboutique.com">contact me</a>.',
                },
              ],
            },
          },
        },
      },
      onFirstConsent: ({ cookie }) => {
        console.log('First consent received', cookie);
      },
      onConsent: ({ cookie }) => {
        console.log('Consent updated', cookie);
        if (cookie.categories.includes('analytics')) {
          initializeGoogleAnalytics('<%= nonce %>');
        }
      },
      onChange: ({ changedCategories, changedServices }) => {
        console.log('Consent changed', changedCategories, changedServices);
        if (changedCategories.includes('analytics')) {
          if (CookieConsent.acceptedCategory('analytics')) {
            initializeGoogleAnalytics('<%= nonce %>');
          } else {
            // Code to disable Google Analytics
            window['ga-disable-G-57BXFE2C88'] = true;
          }
        }
      },
    });
  }
}

// Dark Mode theme handling
const prefersDark =
  localStorage.theme === 'dark' ||
  (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches);

if (prefersDark) {
  document.documentElement.classList.add('dark');
} else {
  document.documentElement.classList.remove('dark');
}

window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
  if (e.matches && !('theme' in localStorage)) {
    document.documentElement.classList.add('dark');
  } else if (!('theme' in localStorage)) {
    document.documentElement.classList.remove('dark');
  }
});

const updateViewportSize = () => {
  const viewportHeight = window.innerHeight;
  const viewportWidth = window.innerWidth;

  // Update elements with viewport size
  const heightField = document.getElementById('viewport-height');
  const widthField = document.getElementById('viewport-width');
  if (heightField) heightField.textContent = viewportHeight;
  if (widthField) widthField.textContent = viewportWidth;
};

// Initialize all functionality when DOM is loaded
document.addEventListener('DOMContentLoaded', () => {
  // Initialize Cookie Consent
  initializeCookieConsent();
  // Initialize updateViewportSize
  updateViewportSize();
  window.addEventListener('resize', updateViewportSize);
});

// Vite client and JavaScript tag logging for development
console.log('Vite ⚡️ Rails');
console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails');
